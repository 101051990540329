import PropTypes from 'prop-types';
import Icon from './Icon';

export default function IcFavoriteOn({variant, className, stroke, fill}) {
  const VARIANTS = {
    multi: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m11.999 6.5475c-4.3139-5.2931-8.8687-1.5321-8.9286 2.5221 0 6.0356 7.2215 10.99 8.9286 10.99 1.707 0 8.9286-4.954 8.9286-10.99-.06-4.0542-4.6148-7.8152-8.9286-2.5221z"
          fill={fill}
        />
        <path
          d="m7.209 2.9727c-.59617.034008-1.1658.17888-1.6914.42383-2.1026.97977-3.4118 3.2613-3.4473 5.6582a1.0001 1.0001 0 000 .015625c0 3.4166 1.9875 6.3624 4.1211 8.4687 1.0668 1.0532 2.1822 1.9029 3.1719 2.5059.98968.60298 1.7243 1.0137 2.6348 1.0137.91044 0 1.647-.41069 2.6367-1.0137.98968-.60298 2.1051-1.4527 3.1719-2.5059 2.1336-2.1063 4.1211-5.0521 4.1211-8.4687a1.0001 1.0001 0 000-.015625c-.035472-2.3969-1.3447-4.6784-3.4473-5.6582-1.8792-.87568-4.3115-.36653-6.4805 1.7793-1.5626-1.5463-3.2555-2.2907-4.791-2.2031zm1.3184 2.0918c.81012.26338 1.7099.90373 2.6973 2.1152a1.0001 1.0001 0 001.5488 0c1.9747-2.423 3.595-2.5617 4.8633-1.9707 1.2671.59047 2.2628 2.2153 2.2891 3.8711-.0041 2.6155-1.6239 5.158-3.5254 7.0352-.95196.93978-1.9622 1.7062-2.8066 2.2207-.84446.51451-1.6526.72266-1.5957.72266.05689 0-.74928-.20815-1.5938-.72266-.84447-.5145-1.8547-1.2809-2.8066-2.2207-1.9015-1.8772-3.5213-4.4197-3.5254-7.0352.026196-1.6558 1.0219-3.2806 2.2891-3.8711.63412-.29549 1.3559-.40791 2.166-.14453z"
          fill={stroke}
        />
      </svg>
    ),
    single: (
      <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="m11.999 6.5475c-4.3139-5.2931-8.8687-1.5321-8.9286 2.5221 0 6.0356 7.2215 10.99 8.9286 10.99 1.707 0 8.9286-4.954 8.9286-10.99-.06-4.0542-4.6148-7.8152-8.9286-2.5221z" />
        <path d="m7.209 2.9727c-.59617.034008-1.1658.17888-1.6914.42383-2.1026.97977-3.4118 3.2613-3.4473 5.6582a1.0001 1.0001 0 000 .015625c0 3.4166 1.9875 6.3624 4.1211 8.4687 1.0668 1.0532 2.1822 1.9029 3.1719 2.5059.98968.60298 1.7243 1.0137 2.6348 1.0137.91044 0 1.647-.41069 2.6367-1.0137.98968-.60298 2.1051-1.4527 3.1719-2.5059 2.1336-2.1063 4.1211-5.0521 4.1211-8.4687a1.0001 1.0001 0 000-.015625c-.035472-2.3969-1.3447-4.6784-3.4473-5.6582-1.8792-.87568-4.3115-.36653-6.4805 1.7793-1.5626-1.5463-3.2555-2.2907-4.791-2.2031zm1.3184 2.0918c.81012.26338 1.7099.90373 2.6973 2.1152a1.0001 1.0001 0 001.5488 0c1.9747-2.423 3.595-2.5617 4.8633-1.9707 1.2671.59047 2.2628 2.2153 2.2891 3.8711-.0041 2.6155-1.6239 5.158-3.5254 7.0352-.95196.93978-1.9622 1.7062-2.8066 2.2207-.84446.51451-1.6526.72266-1.5957.72266.05689 0-.74928-.20815-1.5938-.72266-.84447-.5145-1.8547-1.2809-2.8066-2.2207-1.9015-1.8772-3.5213-4.4197-3.5254-7.0352.026196-1.6558 1.0219-3.2806 2.2891-3.8711.63412-.29549 1.3559-.40791 2.166-.14453z" />
      </svg>
    ),
  };

  return (
    <Icon className={className} label="Favorite On">
      {VARIANTS[variant]}
    </Icon>
  );
}

IcFavoriteOn.propTypes = {
  className: Icon.propTypes.className,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  variant: PropTypes.string,
};

IcFavoriteOn.defaultProps = {
  className: '',
  stroke: '#182C53',
  fill: '#E8EDF4',
  variant: 'single',
};
